/*************************************/
/******** DATEPICKER *****************/
/*************************************/

.ip-datepicker-container {
  position: relative;
  .disabled {
    color: theme-color('light');
    cursor: auto;
  }
  div, .dp-inputfield {}
}

.ip-dp-icon {
  display: block;
  width: 2em;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 2em;
  text-align: center;
  background: red;
  height: 2em;
}

.ip-dp-calendar {
  position: absolute;
  right: 0;
  top: 105%;
  border: 1px solid $gray-400;
  background-color: $white;
  z-index: 1000;
  width: 100%;
  box-sizing: border-box;
  width: 18rem;
  box-shadow: 5px 5px 0 $gray-500;
}

.dp-header {
  text-align: center;
  line-height: 2em;
  color: theme-color('casa-blue');
  border-bottom: 1px solid $gray-400;
}

.dp-change-month {
  display: inline-block;
  width: 2em;
  height: 2em;
  text-align: center;
  line-height: 2em;
  cursor: pointer;
  &:hover {
    background-color: theme-color('casa-blue');
    color: $white;
  }
}

.dp-prev-month {
  float: left;
}

.dp-next-month {
  float: right;
}

.dp-daynames {
  overflow: hidden;
  width: 17.5em;
  padding: .25em 0;
  margin: auto;
  span {
    display: block;
    float: left;
    width: 2.3em;
    height: 1.5em;
    color: theme-color('casa-blue');
    text-align: center;
    margin: 0 .1em .2em;
  }
}
.dp-days {
  overflow: hidden;
  padding: .25em 0;
  width: 17.5em;
  margin: auto;
  span {
    display: block;
    float: left;
    width: 2.3em;
    height: 1.5em;
    line-height: 1.5em;
    border: 1px solid $gray-200;
    border-radius: .2em;
    text-align: center;
    margin: .1em;
    cursor: pointer;
    box-sizing: border-box;
    color: theme-color('dark');
    &:hover {
      background-color: theme-color('casa-blue');
      color: $white;
    }
    &.bulk-day {
      cursor: default;
      border: 0 none;
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.dp-monday {
  clear: left;
}

.dp-days .dp-selected-day {
  border-color: theme-color('casa-light-blue');
  color: $white;
  background-color: theme-color('casa-blue');
}

.dp-footer {
  text-align: center;
  padding: 0.5rem .25rem 0.75rem ;
  /*span {
    display: inline-block;
    padding: 0 .5em;
    border-radius: .25em;
    line-height: 1.8em;
    color: $white;
    background-color: #555;
    cursor: pointer;
  }*/
}
