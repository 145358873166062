body {
	background-color: $white;
}
h1,h2,h3,h4,h5,h6,legend,label {
	color: theme-color("casa-blue");
}
a {
	color: theme-color('casa-blue');
	&:hover {
		color: theme-color('primary');
	}
}
input[type="radio"]:checked + label .outer span {
	background: theme-color("primary");
}
header, .page-ip .header {
	background-color: theme-color("primary");
	#logo {
		width: 15rem;
		background-color: theme-color("secondary");
	}
	#header-title {
		background-color: theme-color("casa-blue");
		> div {
			padding-left: 4rem;
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: -1rem;
				border-right: 1px solid #ffffff;
				-webkit-transform: skewX(26deg);
				transform: skewX(26deg);
				width: 2rem;
				background-color: theme-color("secondary");
			}
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: calc(100% - 1rem);
				width: 2rem;
				transform: skewX(26deg);
				background-color: theme-color("casa-blue");
			}
		}
	}
	.header-icon {
		border-left: 1px solid $white;
		color: $white;
		> span {
			display: inline-block;
			line-height: 4rem;
		}
		.icon-stop {
			color: theme-color('casa-blue') !important;
		}
		&:hover {
			background-color: theme-color("secondary");
			color: $white;
			.icon-stop {
				color: theme-color('warning') !important;
			}
		}
	}
	#miniCart {
		.property-length {
			color: theme-color("casa-blue");
			background-color: $white;
		}
		#cart-details {
			background-color: theme-color("light");
			border: 1px solid theme-color("primary");
		}
	}
	#logout-box {
		.logout-wrapper {
			background-color: theme-color("secondary");
			&:hover {
				background-color: #000;
			}
		}
	}
}
@include media-breakpoint-up(md) {
	> li {
		&:hover {
			> a {
				background-color: #3F4444;
			}
		}
	}
}

.edit-btn {
	background-color: $gray-400;
	color: initial;
	&:hover {
		background-color: theme-color('dark');
		color: $white;
	}
	&.out-of-box {
		background-color: theme-color('dark') !important;
		color: $white !important;
		> i {
			color: inherit !important;
		}
		&:hover {
			background-color: darken(theme-color('dark'), 10) !important;
		}
	}
}
.property-datacard, .client-datacard {
	.card {
		.card-header {
			background-color: theme-color('casa-blue');
			h5 {
				color: $white;
			}
			a.btn {
				@extend .btn-outline-light;
			}
			&.border-success {
				.card-header {
					a.btn {
						@extend .btn-outline-success;
					}
				}
			}
		}
	}
}
.matching-searches-list {
	.item {
		.title {
			background-color: lighten(theme-color('casa-light-blue'), 10);
			border-bottom: 1px solid $gray-200;
			> div {
				background-color: theme-color('casa-light-blue');
			}
		}
		.searches, .presentations {
			h6 {
				background-color: theme-color('casa-blue');
				color: $white;
			}
		}
	}
}
.gutter {
	&:hover {
		background-color: theme-color('casa-blue');
	}
}
.ordered-list {
	> div {
		&:before {
			background-color: theme-color('casa-light-blue');
		}
	}
}
.ip-active {
	background-color: theme-color('warning');
}
.btn-outline-light:hover {
	color: theme-color("primary");
}
.card-image-overlay {
	background-color: rgba(52,58,64,0.8);
}
.file-upload {
	> .box-view {
		color: #495057;
	}
}
.calendar-container {
	.event {
		&.fullday {
			background-color: $orange;
			color: $white;
		}
		&.fromto {
			&:before {
				background-color: $orange;
			}
		}
		.event-content {
			background-color: theme-color('dark');
			color: $white;
			h6 {
				color: inherit;
			}
		}
	}
}

.edit-container{
	.title{
		.icon-back{
			@extend .btn-primary;
		}
	}
}

.new-item-title {
	.icon-back {
		@extend .btn-primary;
	}
	.icons {
		background-color: theme-color('light');
	}
	.my-tooltip {
		@extend .btn-casa-blue;
	}
	.edit-btn {
		background-color: transparent;
		color: $gray-800;
		&:hover {
			background-color: theme-color('casa-blue');
			color: $white;
		}
	}
}
.new-demand {
	.search-block > div {
		border: 1px solid theme-color('casa-light-blue');
	}
}
.demand-datacard {
	&.irodai-ugyfel {
		.new-item-title, .card-header {
			background-color: lighten(theme-color('primary'),50%);
		}
	}
	&.status-warn {
		.new-item-title {
			background-color: lighten(theme-color('warning'),20%);
		}
		.card {
			.card-header {
				background-color: lighten(theme-color('warning'),20%);
			}
			border-color: theme-color('warning');
		}
	}
	&.status-ok {
		.new-item-title {
			background-color: lighten(theme-color('success'),50%);
		}
		.card {
			.card-header {
				background-color: lighten(theme-color('success'),50%);
			}
			border-color: theme-color('success');
		}
	}
	&.status-danger {
		.new-item-title {
			background-color: lighten(theme-color('danger'),50%);
		}
		.card {
			.card-header {
				background-color: lighten(theme-color('danger'),50%);
			}
			border-color: theme-color('danger');
		}
	}
}
#dashboard .dashboard-grid {
	.card-header {
		.back-to-tasks {
			color: theme-color('casa-blue');
			&:hover {
				background-color: theme-color('casa-blue');
				color: $white;
			}
		}
		.toggle-calendar {
			color: theme-color('casa-blue');
			&:hover {
				background-color: theme-color('casa-blue');
				color: $white;
			}
		}
	}
	.tasks-wrapper {
		.task-item {
			border: 1px solid $white;
			background-color: theme-color('casa-light-blue');
			> a, > span {
				color: theme-color('casa-blue');
			}
			&:hover {
				background-color: theme-color('casa-blue');
				> a, > span {
					color: $white;
				}
			}
		}
	}
}
#sidebar {
	#main-menu {
		ul {
			background: theme-color('casa-blue');
			background: -moz-linear-gradient(left, #4e526b 0%, #6d7493 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, #4e526b 0%, #6d7493 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, #4e526b 0%, #6d7493 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4e526b', endColorstr='#6d7493', GradientType=1); /* IE6-9 */
			* {
				color: #ffffff;
			}
			> li {
				border-bottom: 1px solid rgba(255, 255, 255, .2);
				> a {
					&.active {
						background-color: darken(theme-color('casa-blue'), 10);
						> span > i {
							color: $white !important;
						}
					}
				}
				&:hover {
					background-color: lighten(theme-color('casa-blue'), 20);
					> a {
						> span:first-of-type {
							background-color: theme-color('primary');
						}
					}
				}
			}
		}
	}
	@include media-breakpoint-up(lg) {
		#main-menu {
			ul {
				background: $white;
				color: theme-color('casa-blue');
				> li > a > span:first-of-type > i {
					color: theme-color('casa-blue');
				}
				&:hover {
					background: theme-color('casa-blue');
					/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4e526b+0,6d7493+100 */
					background: -moz-linear-gradient(left, #4e526b 0%, #6d7493 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(left, #4e526b 0%, #6d7493 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to right, #4e526b 0%, #6d7493 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4e526b', endColorstr='#6d7493', GradientType=1); /* IE6-9 */
					* {
						color: #ffffff !important;
					}
				}
			}
		}
	}
}
.item-details {
	.details-menu {
		> li {
			&:hover {
				background-color: theme-color('casa-light-blue');
			}
			&.active {
				background-color: theme-color('casa-blue');
				&:before {
					border-color: theme-color('casa-blue') transparent transparent transparent;
				}
				&:hover {
					background-color: lighten(theme-color('casa-blue'), 10);
					&:before {
						border-color: lighten(theme-color('casa-blue'), 10) transparent transparent transparent;
					}
				}
			}
		}
	}
}
.properties-form {
	fieldset {
		background-color: $white;
		legend {
			background-color: $white;
		}
	}
}
.pane {
	background-color: theme-color('casa-light-blue');
	color: theme-color('casa-blue');
	.memo-list,
	.search-block,
	.print-panel {
		background-color: theme-color('casa-light-blue');
	}
	@include media-breakpoint-up(xl) {
		.toggle-btn {
			background-color: theme-color('casa-light-blue');
			&:hover {
				color: theme-color('casa-blue');
			}
		}
		&.closed {
			.toggle-btn {
				background-color: theme-color('casa-blue');
				color: $white;
				&:hover {
					background-color: theme-color('primary');
				}
			}
		}
		&.opened, &.opened-wide {
			.toggle-btn {
				background-color: theme-color('casa-light-blue');
				&:hover {
					background-color: theme-color('primary');
					color: $white;
				}
			}
		}
	}
	@include media-breakpoint-down(xl) {
		.toggle-btn {
			background-color: theme-color('casa-blue');
			color: $white;
			&:hover {
				background-color: theme-color('primary');
			}
		}
	}
}
ip-content-pane {
	.list-block {
		.listing-list {
			> div {
				> div:first-of-type {
					.badge {
						@extend .badge-light;
					}
					.list-item-header {
						background-color: $gray-300;
						color: theme-color('casa-blue');
					}
					&:hover {
						background-color: $gray-600;
						> div {
							color: $white;
							a, .text-muted {
								color: $white !important;
							}
							&.list-item-header {
								color: theme-color('dark');
							}
						}
						.badge {
							@extend .badge-light;
						}
					}
					.edit-btn {
						background-color: theme-color('dark');
						color: $white;
						&:hover {
							background-color: theme-color('secondary');
						}
					}
				}
				&.opened {
					> div:first-of-type {
					  	background-color: $gray-600;
						> div {
							color: $white;
							a, .text-muted {
								color: $white !important;
							}
							&.list-item-header {
								color: theme-color('dark');
							}
						}
						.price {
							background-color: theme-color('casa-light-blue');
						}
						.badge {
							@extend .badge-light;
						}
					}
				}
			}
		}
		.pager {
			border-bottom: 4px solid #ffffff;
		}
	}
}
.listing-list {
	padding: 1rem;
	background-color: $white;
	> div {
		border-color: $gray-400;
		&:hover {
			outline-color: $gray-200;
		}
		> div:first-of-type {
			.badge {
				@extend .badge-light;
			}
			mark {
				background-color: rgba(255,255,255,0.7);
			}
			.thumbnail {
				> .images-length {
					background-color: theme-color('casa-blue');
					color: $white;
				}
			}
			.price {
				color: white;
			}
			.list-item-header {
				background-color: $gray-100;
				&:hover {
					background-color: $gray-200;
				}
			}
			.price {
				background-color: $gray-200;
				color: black;
			}
			.edit-btn {
				background-color: transparent;
				color: $gray-800;
				&:hover {
					background-color: theme-color('dark');
					color: $white;
				}
			}
		}
		&.opened {
			> div:first-of-type {
				background-color: theme-color('light');
				border-bottom: 1px solid $gray-400;
				.badge {
					@extend .badge-light;
				}
			}
		}
		.item-details {
			.item-details-footer {
				.btn-group .btn {
					@extend .btn-outline-dark;
				}
			}
		}
		&.published {
			> div:first-of-type {
				.list-item-header {
					background-color: lighten( theme-color("success"), 40%);
					&:hover {
						background-color: lighten( theme-color("success"), 20%);
					}
				}
				.percent-bar {
					> div {
						background-color: theme-color("success");
						> div {
							background-color: theme-color("success");
						}
					}
				}
			}
			&.opened {
				> div:first-of-type {
					.list-item-header {
						background-color: lighten( theme-color("success"), 20%);
					}
				}
			}
			border-color: theme-color("success");
			&:hover {
				outline-color: lighten( theme-color("success"), 40%);
			}
		}
		&.unpublished {
			> div:first-of-type {
				.list-item-header {
					background-color: lighten( theme-color("primary"), 40%);
					&:hover {
						background-color: lighten( theme-color("primary"), 20%);
					}
				}
				.percent-bar {
					> div {
						background-color: theme-color("primary");
						> div {
							background-color: theme-color("primary");
						}
					}
				}
			}
			&.opened {
				> div:first-of-type {
					.list-item-header {
						background-color: lighten( theme-color("primary"), 20%);
					}
				}
			}
			border-color: theme-color("primary");
			&:hover {
				outline-color: lighten( theme-color("primary"), 40%);
			}
		}
		&.awaiting {
			> div:first-of-type {
				.list-item-header {
					background-color: lighten( theme-color("warning"), 40%);
					&:hover {
						background-color: lighten( theme-color("warning"), 20%);
					}
				}
				.percent-bar {
					> div {
						background-color: theme-color("warning");
						> div {
							background-color: theme-color("warning");
						}
					}
				}
			}
			&.opened {
				> div:first-of-type {
					.list-item-header {
						background-color: lighten( theme-color("warning"), 20%);
					}
				}
			}
			border-color:  theme-color("warning");
			&:hover {
				outline-color: lighten( theme-color("warning"), 40%);
			}
		}
		&.private {
			> div:first-of-type {
				.list-item-header {
					background-color: lighten( theme-color("private"), 20%);
					&:hover {
						background-color: lighten( theme-color("private"), 10%);
					}
				}
				.percent-bar {
					> div {
						background-color: theme-color("private");
						> div {
							background-color: theme-color("private");
						}
					}
				}
			}
			&.opened {
				> div:first-of-type {
					.list-item-header {
						background-color: lighten( theme-color("private"), 10%);
					}
				}
			}
			border-color:  theme-color("private");
			&:hover {
				outline-color: lighten( theme-color("private"), 20%);
			}
		}
		&.agreement {
			> div:first-of-type {
				.list-item-header {
					background-color: lighten( #6f42c1, 40%);
					&:hover {
						background-color: lighten( #6f42c1, 20%);
					}
				}
				.percent-bar {
					> div {
						background-color: #6f42c1;
						> div {
							background-color: #6f42c1;
						}
					}
				}
			}
			&.opened {
				> div:first-of-type {
					.list-item-header {
						background-color: lighten( #6f42c1, 20%);
					}
				}
			}
			border-color:  #6f42c1;
			&:hover {
				outline-color: lighten( #6f42c1, 40);
			}
		}
		&.draft {
			> div:first-of-type {
				.list-item-header {
					background-color: $gray-500;
					&:hover {
						background-color: $gray-300;
					}
				}
			}
			&.opened {
				> div:first-of-type {
					.list-item-header {
						background-color: $gray-300;
					}
				}
			}
			border-color:  black;
			&:hover {
				outline-color: $gray-400;
			}
		}
		&.before-delete {
			> div:first-of-type {
				* {
					@extend .text-muted;
				}
				.list-item-header {
					 background-color: $gray-200;
					&:hover {
						background-color: $gray-400;
					}
				}
				.price {
					background-color: $gray-400;
				}
				.edit-btn {
					color: $gray-600;
					&:hover {
						background-color: $gray-600;
						color: $white;
					}
				}
			}
			border-color:  $gray-400;

			.item-details {
				.item-details-footer {
					background-color: $gray-200;
				}
			}
			&:hover {
				outline-color: $gray-200;
			}
			.item-details {
				.item-details-footer {
					.btn-group .btn {
						@extend .btn-outline-light;
					}
				}
			}
		}
	}
	&.client-list {
		> div {
			> div:first-of-type {
				.list-item-header {
					mark {
						background-color: $gray-200;
					}
					&:hover {
						mark {
							background-color: theme-color('casa-blue');
							color: $white;
						}
					}
					.myclient {
						background-color: theme-color('warning');
					}
					.myoffice {
						background-color: $orange;
					}
				}
			}
		}
	}
	&.statement-list {
		>div > div > div:first-child {
			background-color: theme-color('casa-light-blue');
		}
		> div > div {
			&.item-details {
				.properties {
					.list-group-item a {
						background-color: theme-color('casa-blue');
						color: $white;
					}
				}
			}
		}
		.edit-btn {
			&.original {
				background-color: theme-color('casa-light-blue');
			}
			&.signed {
				background-color: theme-color('success');
				color: $white;
				&:hover {
					background-color: darken(theme-color('success'), 20);
				}
			}
			&.trashed {
				background-color: theme-color('dark');
				color: $white;
				&:hover {
					background-color: darken(theme-color('dark'), 20);
				}
			}
			&.upload {
				background-color: $orange;
				color: $white;
				&:hover {
					background-color: darken($orange, 20);
				}
			}
		}
	}
	&.demand-list {
		> .archive {
				* { opacity: 0.9; }
				border-color: transparent;
			&:hover {
				* { opacity: 1; }
				border-color: initial;
			}
		}
		 .demand-property {
			 .edit-btn {
				 background-color: transparent;
				 color: $gray-800;
				 &:hover {
					 background-color: theme-color('casa-blue');
					 color: $white;
				 }
			 }
		 }
	 }
	&.offices-list {
		> div {
			border-color: $gray-400;
		}
	}
	&.privatead-list {
		> div > div:first-of-type {
			&:hover {
				background-color: $gray-200;
			}
			> div:nth-of-type(5) {
				> span > i {
					&.icon-uncalled {
						background-color: theme-color('casa-light-blue');
					}
					&.icon-called-by-me {
						background-color: lighten(theme-color('success'), 30);
					}
					&.icon-called-by-other {
						background-color: $gray-600;
						color: $white;
					}
				}

			}
		}

	}
}
.listing-grid {
	padding: 1rem;
	> div {
		.card {
			border-width: 4px;
			&:hover {
				outline-color: $gray-400;
			}
			.card-header {
				border-bottom-color: rgba(0, 0, 0, 0.125);
				.nav-pills .nav-item .nav-link {
					background-color: transparent;
					&.active {
						background-color: $gray-600;
					}
					&:hover {
						background-color: lighten( $gray-600, 30%);
					}
				}
			}
			.tab-content {
				.icons {
					background-color: $gray-200;
				}
				.main-info {
					> div {
						&:nth-of-type(1) {
							background-color: theme-color('light');
						}
						&:nth-of-type(2) {
							color: $white;
							background-color: $gray-600;
						}
						&:nth-of-type(3) {
							background-color: theme-color('light');
						}
					}
				}
			}
			.created {
				background-color: theme-color('light');
			}
			.card-footer {
				.btn-group .btn {
					&:not(.out-of-box) {
						@extend .btn-outline-dark;
					}
					&.out-of-box {
						background-color: theme-color('dark');
						color: $white;
						&:hover {
							background-color: darken(theme-color('dark'), 10);
						}
					}
				}
			}
			.card-label {
				background-color: theme-color("light");
			}
		}
		&.published {
			.card {
				@extend .border-success;
				.percent-bar {
					> div {
						background-color: theme-color("success");
						> div {
							background-color: theme-color("success");
						}
					}
				}
				&:hover {
					outline-color: lighten( theme-color('success'), 30%);
				}
				.card-header {
					border-bottom-color: theme-color('success');
					.nav-pills .nav-item .nav-link {
						background-color: transparent;
						&.active {
							background-color: theme-color('success');
						}
						&:hover {
							background-color: lighten( theme-color('success'), 30%);
						}
					}
				}
				.tab-content {
					.icons {
						background-color: lighten( theme-color("success"), 30%);
					}
					.main-info {
						> div {
							&:nth-of-type(1) {
								color: theme-color('success');
							}
							&:nth-of-type(2) {
								background-color: theme-color('success');
							}
							&:nth-of-type(3) {
								color: theme-color('success');
							}
						}
					}
				}
				.created {
					background-color: theme-color('success');
				}
				.card-footer {
					.btn-group .btn {
						&:not(.out-of-box) {
							@extend .btn-outline-success;
						}

					}
				}
			}
		}
		&.unpublished {
			.card {
				@extend .border-danger;
				.percent-bar {
					> div {
						background-color: theme-color("primary");
						> div {
							background-color: theme-color("primary");
						}
					}
				}
				&:hover {
					outline-color: lighten( theme-color('primary'), 30%);
				}
				.card-header {
					border-bottom-color: theme-color('primary');
					.nav-pills .nav-item .nav-link {
						background-color: transparent;
						&.active {
							background-color: theme-color('primary');
						}
						&:hover {
							background-color: lighten( theme-color('primary'), 30%);
						}
					}
				}
				.tab-content {
					.icons {
						background-color: lighten( theme-color("primary"), 30%);
					}
					.main-info {
						> div {
							&:nth-of-type(1) {
								color: theme-color('primary');
							}
							&:nth-of-type(2) {
								background-color: theme-color('primary');
							}
							&:nth-of-type(3) {
								color: theme-color('primary');
							}
						}
					}
				}
				.created {
					background-color: theme-color('primary');
				}
				.card-footer {
					.btn-group .btn {
						&:not(.out-of-box) {
							@extend .btn-outline-primary;
						}
					}
				}
			}
		}
		&.awaiting {
			.card {
				@extend .border-warning;
				.percent-bar {
					> div {
						background-color: theme-color("warning");
						> div {
							background-color: theme-color("warning");
						}
					}
				}
				&:hover {
					outline-color: lighten( theme-color('warning'), 30%);
				}
				.card-header {
					border-bottom-color: theme-color('warning');
					.nav-pills .nav-item .nav-link {
						background-color: transparent;
						&.active {
							background-color: theme-color('warning');
						}
						&:hover {
							background-color: lighten( theme-color('warning'), 30%);
						}
					}
				}
				.tab-content {
					.icons {
						background-color: lighten( theme-color("warning"), 30%);
					}
					.main-info {
						> div {
							&:nth-of-type(1) {
								color: theme-color('warning');
							}
							&:nth-of-type(2) {
								background-color: theme-color('warning');
							}
							&:nth-of-type(3) {
								color: theme-color('warning');
							}
						}
					}
				}
				.created {
					background-color: theme-color('warning');
				}
				.card-footer {
					.btn-group .btn {
						&:not(.out-of-box) {
							@extend .btn-outline-warning;
						}
					}
				}
			}
		}
		&.private {
			.card {
				@extend .border-private;
				.percent-bar {
					> div {
						background-color: theme-color("private");
						> div {
							background-color: theme-color("private");
						}
					}
				}
				&:hover {
					outline-color: lighten( theme-color('private'), 10%);
				}
				.card-header {
					border-bottom-color: theme-color('private');
					.nav-pills .nav-item .nav-link {
						background-color: transparent;
						&.active {
							background-color: theme-color('private');
						}
						&:hover {
							background-color: lighten( theme-color('private'), 20%);
						}
					}
				}
				.tab-content {
					.icons {
						background-color: lighten( theme-color("private"), 20%);
					}
					.main-info {
						> div {
							&:nth-of-type(1) {
								color: theme-color('private');
							}
							&:nth-of-type(2) {
								background-color: theme-color('private');
							}
							&:nth-of-type(3) {
								color: theme-color('private');
							}
						}
					}
				}
				.created {
					background-color: theme-color('private');
				}
				.card-footer {
					.btn-group .btn {
						&:not(.out-of-box) {
							@extend .btn-outline-private;
						}
					}
				}
			}
		}
		&.agreement {
			.card {
				@extend .border-warning;
				.percent-bar {
					> div {
						background-color: #6f42c1;
						> div {
							background-color: #6f42c1;
						}
					}
				}
				&:hover {
					outline-color: lighten( #6f42c1, 30%);
				}
				.card-header {
					border-bottom-color: #6f42c1;
					.nav-pills .nav-item .nav-link {
						background-color: transparent;
						&.active {
							background-color: #6f42c1;
						}
						&:hover {
							background-color: lighten( #6f42c1, 30%);
						}
					}
				}
				.tab-content {
					.icons {
						background-color: lighten( #6f42c1, 30%);
					}
					.main-info {
						> div {
							&:nth-of-type(1) {
								color: #6f42c1;
							}
							&:nth-of-type(2) {
								background-color: #6f42c1;
							}
							&:nth-of-type(3) {
								color: #6f42c1;
							}
						}
					}
				}
				.created {
					background-color: #6f42c1;
				}
				.card-footer {
					.btn-group .btn {
						&:not(.out-of-box) {
							@extend .btn-outline-purple;
						}
					}
				}
			}
		}
		&.draft {
			.card {
				@extend .border-dark;
				&:hover {
					outline-color: lighten( theme-color('dark'), 30%);
				}
				.card-header {
					border-bottom-color: theme-color('dark');
					.nav-pills .nav-item .nav-link {
						background-color: transparent;
						&.active {
							background-color: theme-color('dark');
						}
						&:hover {
							background-color: lighten( theme-color('dark'), 30%);
						}
					}
				}
				.tab-content {
					.icons {
						background-color: lighten( theme-color("dark"), 30%);
					}
					.main-info {
						> div {
							&:nth-of-type(1) {
								color: theme-color('dark');
							}
							&:nth-of-type(2) {
								background-color: theme-color('dark');
							}
							&:nth-of-type(3) {
								color: theme-color('dark');
							}
						}
					}
				}
				.created {
					background-color: theme-color('dark');
				}
				.card-footer {
					.btn-group .btn {
						&:not(.out-of-box) {
							@extend .btn-outline-dark;
						}
					}
				}
			}
		}
		&.before-delete {
			@extend .text-muted;
			.card {
				border-color: $gray-400;
				&:hover {
					outline-color: lighten( $gray-400, 30%);
				}
				.card-header {
					border-bottom-color: $gray-400;
					.nav-pills .nav-item .nav-link {
						background-color: transparent;
						&.active {
							background-color: $gray-400;
						}
						&:hover {
							background-color: lighten( $gray-400, 30%);
						}
					}
				}
				* {
					color: $gray-400 !important;
				}
				.icons {
					background-color: $gray-200;
				}
				.tab-content {
					.main-info {
						> div {
							&:nth-of-type(2) {
								background-color: theme-color('light');
							}
						}
					}
				}
				.created {
					background-color: theme-color('light');
				}
				.card-footer {
					.btn-group .btn {
						&:not(.out-of-box) {
							@extend .btn-outline-light;
						}
					}
				}
			}
		}
	}
}
.listing-blog {
	padding: 1rem;
	> div  {
		background-color: $white;
		&:hover {
			outline-color: $gray-400;
		}
		.listing-blog-item {
			.item-footer {
				.price {
					background-color: theme-color('casa-light-blue');
				}
			}
		}
		.item-footer {
			.btn-group .edit-btn {
				&.out-of-box {
					background-color: theme-color('dark') ;
					color: $white ;
					&:hover {
						background-color: darken(theme-color('dark'), 10) ;
					}
				}
			}
		}
		&.published{
			@extend .border-success;
			.percent-bar {
				> div {
					background-color: theme-color("success");
					> div {
						background-color: theme-color("success");
					}
				}
			}
			&:hover {
				outline-color: lighten( theme-color('success'), 30%);
			}
			mark {
				background-color: lighten( theme-color('success'), 50%);
			}
			.item-header .item-title {
				color: darken( theme-color('success'), 20%);
			}
			.icons {
				background-color: lighten( theme-color('success'), 30%);
			}
			.item-footer {
				.price {
					background-color: theme-color('success');
					color: $white;
				}
				.btn-group .edit-btn:not(.out-of-box) {
					@extend .btn-outline-success;
				}
			}
			.item-details {
				border-color: theme-color('success');
				.item-details-footer {
					.btn {
						@extend .btn-outline-success;
					}
				}
			}
		}
		&.unpublished{
			@extend .border-primary;
			.percent-bar {
				> div {
					background-color: theme-color("primary");
					> div {
						background-color: theme-color("primary");
					}
				}
			}
			mark {
				background-color: lighten( theme-color('primary'), 50%);
			}
			.item-header .item-title {
				color: darken( theme-color('primary'), 10%);
			}
			.icons {
				background-color: lighten( theme-color('primary'), 30%);
			}
			.item-footer {
				.price {
					background-color: theme-color('primary');
					color: $white;
				}
				.btn-group .edit-btn:not(.out-of-box) {
					@extend .btn-outline-danger;
				}
			}
			.item-details {
				border-color: theme-color('primary');
				.item-details-footer {
					.btn {
						@extend .btn-outline-primary;
					}
				}
			}
		}
		&.awaiting{
			@extend .border-warning;
			.percent-bar {
				> div {
					background-color: theme-color("warning");
					> div {
						background-color: theme-color("warning");
					}
				}
			}
			&:hover {
				outline-color: lighten( theme-color('warning'), 30%);
			}
			mark {
				background-color: lighten( theme-color('warning'), 50%);
			}
			.item-header .item-title {
				color: darken( theme-color('warning'), 20%);
			}
			.icons {
				background-color: lighten( theme-color('warning'), 30%);
			}
			.item-footer {
				.price {
					background-color: theme-color('warning');
					color: $white;
				}
				.btn-group .edit-btn:not(.out-of-box) {
					@extend .btn-outline-warning;
				}
			}
			.item-details {
				border-color: theme-color('warning');
				.item-details-footer {
					.btn {
						@extend .btn-outline-warning;
					}
				}
			}
		}
		&.private{
			@extend .border-private;
			.percent-bar {
				> div {
					background-color: theme-color("private");
					> div {
						background-color: theme-color("private");
					}
				}
			}
			&:hover {
				outline-color: lighten( theme-color('private'), 20%);
			}
			mark {
				background-color: lighten( theme-color('private'), 50%);
			}
			.item-header .item-title {
				color: darken( theme-color('private'), 20%);
			}
			.icons {
				background-color: lighten( theme-color('private'), 20%);
			}
			.item-footer {
				.price {
					background-color: theme-color('private');
					color: $white;
				}
				.btn-group .edit-btn:not(.out-of-box) {
					@extend .btn-outline-private;
				}
			}
			.item-details {
				border-color: theme-color('private');
				.item-details-footer {
					.btn {
						@extend .btn-outline-private;
					}
				}
			}
		}
		&.agreement {
			@extend .border-warning;
			.percent-bar {
				> div {
					background-color: #6f42c1;
					> div {
						background-color: #6f42c1;
					}
				}
			}
			&:hover {
				outline-color: lighten( #6f42c1, 30%);
			}
			mark {
				background-color: lighten( #6f42c1, 50%);
			}
			.item-header .item-title {
				color: darken( #6f42c1, 20%);
			}
			.icons {
				background-color: lighten( #6f42c1, 30%);
			}
			.item-footer {
				.price {
					background-color: #6f42c1;
					color: $white;
				}
				.btn-group .edit-btn:not(.out-of-box) {
					@extend .btn-outline-purple;
				}
			}
			.item-details {
				border-color: #6f42c1;
				.item-details-footer {
					.btn {
						@extend .btn-outline-purple;
					}
				}
			}
		}
		&.draft{
			@extend .border-dark;
			&:hover {
				outline-color: lighten( theme-color('dark'), 30%);
			}
			mark{
				background-color: lighten( theme-color('dark'), 50%);
			}
			.icons {
				background-color: lighten( theme-color('dark'), 30%);
			}
			.item-footer {
				.price {
					background-color: theme-color('dark');
					color: $white;
				}
				.btn-group .edit-btn:not(.out-of-box) {
					@extend .btn-outline-dark;
				}
			}
			.item-details {
				border-color: theme-color('dark');
				.item-details-footer {
					.btn {
						@extend .btn-outline-dark;
					}
				}
			}
		}
		&.before-delete {
			@extend .text-muted;
			@extend .border-light;
			&:hover {
				outline-color: theme-color('light');
			}
			mark{
				background-color: $gray-200;
			}
			.icons {
				background-color: $gray-200;
			}
			.item-footer {
				.price {
					background-color: $gray-400;
					color: $white;
				}
				.btn-group .edit-btn:not(.out-of-box) {
					@extend .btn-outline-light;
				}
			}
			.item-details {
				border-color: theme-color('light');
				.item-details-footer {
					.btn {
						@extend .btn-outline-light;
					}
				}
			}
		}
	}
}
.suggestion-list {
	> div > div {
		.suggestion-title {
			background-color: $gray-200;
		}
	}
}
.users-blog {
	> div > div {
		background-color: $white;
		border: 2px solid theme-color('casa-blue');
	}
}
.searching-client {
	border: 1px solid $gray-200;
	&:hover {
		outline: 2px solid $gray-400;
	}
	.name {
		background-color: theme-color('casa-light-blue');
	}
	.edit-btn {
		background-color: $gray-200;
		color: initial;
		&:hover {
			background-color: theme-color('secondary');
			color: $white;
		}
	}
	&.status-warn {
		border-color: theme-color('warning');
		.status {
			background-color: theme-color('warning');
		}
	}
	&.status-ok {
		border-color: theme-color('success');
		.status {
			background-color: theme-color('success');
			color: $white;
		}
	}
	&.status-danger {
		border-color: theme-color('danger');
		.status {
			background-color: theme-color('danger');
			color: $white;
		}
	}
}
ip-content-pane, ip-list-pane-pager {
	.pane {
		.nav-pills {
			border-bottom: 2px solid theme-color('casa-blue');
			.nav-link {
				color: theme-color('casa-blue');
				&.active {
					color: $white;
				}
			}
		}
	}
	.content-block, .list-block {
		.icons {
			> span {
				color: $black;
				&:hover {
					background-color: $warning;
				}
			}
		}
	}
}
.page-irodak {
	.listing {
		> div {
			&:before {
				background-color: $white;
			}
		}
	}
}
ip-gallery-admin {
	.gallery-admin-item {
		&.over {
			outline: 4px solid theme-color('primary');
		}
		.order-item {
			> span {
				color: $white;
				background-color: theme-color("primary");
				&.floor-plan {
					background-color: theme-color("info");
				}
			}
		}
		.overlayed {
			.btn:hover {
				color: theme-color("primary");
			}
			.handle {
				background-color: theme-color('casa-blue');
				color: $white;
				&:hover {
					background-color: theme-color('primary');
				}
			}
		}
		&:hover {
			.overlayed {
				.btn:hover {
					color: theme-color("primary");
				}
			}
		}
	}
}
form[name="search-form"] {
	.search-column {
		&:last-child {
			background-color: rgba(255,255,255,.3);
		}
	}

	.button-block {

		.btn-color {
			background-color: $white;
			border-color: theme-color('dark');
			color:  theme-color('dark');
			&:hover {
				color: $white;
				background-color: theme-color('casa-blue');
			}
			&:nth-of-type(1).active {
				background-color: #a2abb4;
				&:hover {
					background-color: darken(#a2abb4, 10%);
				}
			}
			&:nth-of-type(2).active {
				background-color: #ffcc33;
				&:hover {
					background-color:  darken(#ffcc33, 10%);
				}
			}
			&:nth-of-type(3).active {
				background-color: #2cd854;
				&:hover {
					background-color:  darken(#2cd854, 10%);
				}
			}
			&:nth-of-type(4).active {
				background-color: #d0d3dd;
				&:hover {
					background-color:  darken(#d0d3dd, 10%);
				}
			}
			&:nth-of-type(5).active {
				background-color: theme-color('primary');
				color: $white;
				&:hover {
					background-color:  darken(theme-color('primary'), 10%);
				}
			}
		}
	}
}
