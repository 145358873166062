@import "../node_modules/bootstrap/scss/functions";

$gray-100: #f8f9fa ;
$gray-200: #e9ecef ;
$red:     #e40046 ;
$orange:  #ef7f3c;
$yellow:  #ffc107;
$white: #ffffff;

$theme-colors: (
	primary: #e3003a,
	secondary: #3f4444,
	purple: #6f42c1,
	casa-blue: #4e526b,
	casa-light-blue: #bcc3ce,
	dark: #3c3c3b, //#292B38
	private: royalblue
) ;

$enable-rounded:            false;
$enable-hover-media-query:  false;


$sizes: (
        10: 10%,
        15: 15%,
        16: 16.66%,
        20: 20%,
        33: 33.33%,
        40: 40%,
        45: 45%,
        55: 55%,
        60: 60%,
        66: 66.66%,
        70: 70%,
        80: 80%,
        85: 80%,
        90: 90%,
        95: 95%
);

$body-bg:       $gray-100;

$link-color: theme-color('casa-blue');
$link-hover-decoration: none ;
$link-hover-color: darken($link-color, 15%);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
) ;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1540px
);

$border-color: $gray-200 ;

$component-active-bg:    #4e526b;

$tooltip-color: black;
$tooltip-bg: $yellow;
$tooltip-opacity: 1;


