.page-emailbemutato {
	header, #sidebar {
		display: none !important;
	}
	#presentation-wrapper {
		width: 100%;
	}
	.sales-card {
		max-width: 30rem;
		flex: 0 0 auto;
		text-align: center;
		img {
			display: inline-block;
			margin: 1rem auto;
			width: 10rem;
			height: 10rem;
			object-fit: cover;
		}
		> div {
			padding: 1rem;
		}
	}
	#agent {
		min-height: 100vh;
		padding: 3rem 0 10rem;
		margin: 0 auto;
		align-content: center;
		width: 100%;
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		position: relative;
		@include media-breakpoint-up(md) {
			flex-direction: row;
			flex-wrap: wrap;
		}
		.logo {
			flex: 0 0 100%;
			padding: 1rem;
		}
		.hello {
			flex: 1 1 auto;
			padding: 1rem;
			@include media-breakpoint-up(md) {
				flex: 0 1 30rem;
			}
		}
		.start-btn {
			position:  absolute;
			left: 50%;
			bottom: 4rem;
			padding: 1rem 2rem;
			@extend .btn-casa-blue;
			transform: translateX(-50%);
			text-align: center;
			span {
				display: block;
			}
		}
	}
	#main-nav {
		position: fixed;
		top: 0;
		left: -6rem;
		z-index: 1001;
		transition: all 250ms ease;
		background-color: $white;
		.toggle-btn {
			position: absolute;
			top: 0;
			right: 0;
			width: 4rem;
			height: 4rem;
			color: $white;
			transform: translateX(100%);
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: theme-color('casa-blue');
			cursor: pointer;
			z-index: 2;
			&:hover {
				background-color: darken(theme-color('casa-blue'), 20%);
			}
			> i {
				display: inline-block;
			}
		}
		div[slim-scroll] {
			width: 6rem;
			height: 100vh;
			box-shadow: 5px 5px 15px rgba(0,0,0,.5);
			.menu-item {
				position: relative;
				display: block;
				> div:first-child {
					display: none;
					position: absolute;
					top: 0; left:0; right: 0; bottom: 0;
					background-color: rgba(0,0,0, .3);
					transition: inherit;
					> span {
						display: block;
						width: 2rem;
						height: 2rem;
						border-radius: 50%;
						background-color: $white;
						position: absolute;
						text-align: center;
						line-height: 2rem;
						top:50%;
						left: 50%;
						transform: translate(-50%,-50%);
					}
				}
				> img {
					width: 100%;
					height: 4.5rem;
					object-fit: cover;
				}
				&:hover {
					> div {
						display: block;
					}
				}
			}
		}
		&:hover {
			left: 0;
		}

	}
	.searches {
		h5{
			margin: 3rem 0 2rem;
			text-align: center;
		}
		ul{
			list-style-type: none;
			margin: 1rem;
			padding: 0;
			li{
				padding: .75rem .5rem;
				margin-bottom: 1rem;
				background-color: $gray-100;
			}
		}
		.buttons{
			text-align: center;
			.alert{
				display: inline-block;
			}
		}
	}
	.to-top {
		@extend .btn, .btn-casa-blue;
		position: fixed;
		right: 2rem;
		bottom:2rem;
		width: 3rem;
		height: 3rem;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1101;
		border-radius: 50%;
	}
	article {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		grid-gap: 1rem;
		align-items: start;
		.header {
			grid-column: 1 / -1;
			position: sticky;
			top: 0;
			z-index: 1000;
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 2rem;
			background-color: theme-color('primary');
			> div {
				height: 2rem;
			}
			.property-num {
				order: 1;
				width: 2rem;
				height: 2rem;
				@include media-breakpoint-up(md) {
					width: 4rem;
				}
				> div {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 1.5rem;
					height: 1.5rem;
					margin: 0.25rem;
					background-color: $white;
					border-radius: 50%;
					> span {
						display: inline-block;
						font-weight: bold;
						font-size: 1rem;
						color: theme-color('primary');
					}
				}
			}
			#logo {
				order: 2;
				position: relative;
				padding-right: 1rem;
				background-color: #3f4444;
				flex: 0 0 auto;
				margin-right: auto;
				img {
					height: 2rem;
					width: auto;
				}

			}
			.header-title {
				order: 5;
				flex: 0 0 100%;
				line-height: 2rem;
				padding: 0 7rem 0 .5rem;
				margin-right: -2rem;
				font-weight: bold;
				font-size: 1rem;
				background-color: theme-color('casa-light-blue');
				@include media-breakpoint-up(md) {
					flex: 1 1 100%;
				}
				.title-text {
					display: block;
					width: 100%;
					@extend .text-truncate;
					text-align: center;
					.property-id {
						font-size: .7em;
						display: inline-block;
						background-color: $gray-400;
						line-height: 1em;
						padding: .25em .5em;
						vertical-align: text-top;
					}
				}
			}
			.price {
				order: 6;
				flex: 0 0 100%;
				display: inline-block;
				height: 2rem;
				line-height: 2rem;
				padding: 0 1rem;
				margin-left: 2rem;
				background-color: theme-color('primary');
				color: $white;
				font-weight: bold;
				font-size: 1rem;
				white-space: nowrap;
				text-align: center;
				position: relative;
				> span {
					display: inline-block;
				}
				@include media-breakpoint-up(md) {
					flex: 1 1 auto;
				}
			}
			.nav-btn {
				width: 2rem;
				height: 2rem;
				display: flex;
				background-color: theme-color('casa-blue');
				color: $white;
				cursor: pointer;
				i {
					margin: auto;
				}
				&:hover {
					background-color: darken(theme-color('casa-blue'), 10);
				}
			}
			.nav-btn--up, .nav-btn--down {
				@extend .nav-btn;
				position:  relative;
				z-index: 2;
				width: 2.6rem;
				flex: 0 0 auto;
				order: 3;
				justify-self: end;
			}
			.nav-btn--up {
				order: 3;
			}
			.nav-btn--down {
				order: 4;
			}
			.request-block {
				.prezi {
					font-size: .8rem;
					line-height: 1rem;
					height: 4rem;
					width: 7rem;
					position: relative;
					top: -2rem;
					display: flex;
					align-items: center;
					justify-content: center;
					&.ok {
						cursor: initial;
					}
				}
			}
			.remove-cart {
				width: 5rem;
				height: 4rem;
				display: flex;
				background-color: theme-color('dark');
				color: $white;
				font-size: 2rem;
				margin: 0 1rem 0 0;
				cursor: pointer;
				position: relative;
				i {
					margin: auto;
					display: inline-block;
				}
				&:hover {
					background-color: darken(theme-color('casa-blue'), 10);
					&:before {
						background-color: darken(theme-color('casa-blue'), 10);
					}
				}
			}
			@include media-breakpoint-up(lg) {
				flex-wrap: nowrap;
				.property-num, #logo, .header-title, .price, .nav-btn--up, .nav-btn--down {
					order: initial;
				}
				> div {
					height: 4rem;
				}
				.property-num {
					height: 4rem;
					> div {
						width: 2.5rem;
						height: 2.5rem;
						margin: 0.75rem;
						> span {
							font-size: 1.3rem;
						}
					}
				}
				#logo img {
					height: 4rem;
					margin-right: initial;
				}
				.header-title {
					line-height: 4rem;
					font-size: 1.2rem;
					padding: 0 2rem;
					.title-text {
						text-align: left;
					}
				}
				.price {
					flex: 0 0 auto;
					height: 4rem;
					line-height: 4rem;
					font-size: 1.3rem;
					&:before {
						display: block;
					}
				}
				.nav-btn--up, .nav-btn--down {
					height: 4rem;
					justify-self: initial;
				}
				.request-block .prezi {
					position: static;
					top:0;
				}
			}
		}
		.title {
			grid-column: 1 / -1;
			padding: 0 1rem 1rem 1rem;
			text-align: center;
		}
		.tiles {
			grid-column: 1 / -1;
			.tiles__1, .tiles__2 {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				> div {
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0.25rem;
					line-height: 1.2;
				}
			}
			.tiles__1 {
				> div {
					background-color: theme-color('casa-light-blue');
					flex-flow: column;
					height: 4rem;
					padding: 1rem;
					> span {
						display: inline-block;
						&:last-child {
							font-weight: bold;
							font-size: 1.3rem;
						}
					}
				}
			}
			.tiles__2 {
				> div {
					background-color: theme-color('warning');
					padding: .5rem;
					> span {
						display: inline-block;
						&:first-child {
							font-size: 1.3rem;
						}
						&:last-child {
							padding-left: .5rem;
							font-weight: bold;
							font-size: .8rem;
						}
					}
				}
			}
		}
		.gallery {
			grid-column: 1 / -1;
			padding: 1rem;
			> div {
				display: grid;
				grid-template-columns: repeat(auto-fit, 3rem);
				grid-auto-rows: 3rem;
				grid-gap: .5rem;
				grid-auto-flow: dense;
				@include media-breakpoint-up(xl) {
					grid-template-columns: repeat(auto-fit, 4rem);
					grid-auto-rows: 4rem;
					justify-content: end;
				}
				@include media-breakpoint-up(xxl) {
					grid-template-columns: repeat(auto-fit, 5rem);
					grid-auto-rows: 5rem;
				}
				> div {
					grid-column: span 2;
					grid-row: span 2;
					cursor: pointer;
					> img {
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
					&.main-image {
						grid-row: span 6;
						grid-column: span 6;
					}
					&.landscape {
						grid-column: span 3;
						grid-row: span 2;
					}
					&.portrait {
						grid-column: span 2;
						grid-row: span 3;
					}
				}
			}
		}
		.desc {
			grid-column: 1 / -1;
			padding: 1rem;
		}
		.tags {
			grid-column: 1 / -1;
			padding: 0 1rem;
			text-align: center;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: center;
			> div {
				flex: 1;
				display: inline-block;
				padding: 1rem;
				vertical-align: top;
				min-width: 15rem;
				.item {
					padding: 0.125rem ;
					border-bottom: 1px solid $gray-200;
					&:last-child {
						border-bottom: none;
					}
					span {
						display: block;
						&:nth-of-type(2) {
							font-weight: bold;
						}
					}
				}
			}
			@include media-breakpoint-up(xl) {
				text-align: left;
				> div {
					flex: 1 1 auto;
				}
			}
		}
		&:first-child {
			.header {
				margin-top:0;
			}
		}
		.footer {
			grid-column: 1 / -1;
			min-height: 15rem;
			margin-bottom: -15rem;
			padding: 0 1rem;
			z-index: 1001;
			.wrapper {
				max-width: 70rem;
				margin: 2rem auto 0;
			}
			.sales-card {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				max-width: initial;
				margin-top: 2rem;
				img {
					margin: initial;
					border-radius: 50%;
				}
			}
			.presentation-progress {
				text-align: center;
				max-width: 100%;
				line-height: 3rem;
			}
			.unsubscribe {
				text-align: center;
				margin-bottom: 2rem;
				button {
					@extend .btn ;
					transition: background-color .3s ease;
				}
			}
		}
		@include media-breakpoint-up(xl) {
			grid-template-columns: 15% 1fr 1fr 1fr 15%;
			grid-auto-flow: dense;
			.gallery {
				grid-column: 1 /span 3;
				grid-row: 4 / 6;
			}
			.tags {
				grid-column: 4 / -1;
			}
			.desc {
				grid-column: 2 / -2;
			}
		}



	}
	.header {
		.request-block-v2 {
			position: absolute;
			right: 0;
			top: 8rem;
			width: 4rem;
			&:hover {
				width: 14rem;
				height: 8rem;
				box-shadow: -4px 4px 3px rgba(0,0,0,.5);
				.verbose{
					width: 10rem;
					overflow: inherit;
					height: auto;
				}
			}
			.visible-icons {
				width: 4rem;
				height: 8rem;
				position: absolute;
				left: 0;
				top:0;
				z-index: 2;
				background-color: #fff;
				img {
					display: inline-block;
					width: 4rem;
					height: 4rem;
					object-fit: cover;
				}
				.prezi {
					width: 4rem;
					height: 4rem;
					margin: 0;
					padding: 0;
					text-align: center;
				}
				.prezi i{
					font-size: 2rem;
					line-height: 4rem;
				}
			}
			.verbose {
				overflow: hidden;
				width: 0;
				position: absolute;
				left: 4rem;
				top: 0;
				.prezi {
					font-size: .9rem;
					height: 4rem;
					width: 10rem;
					padding: .6em 0 0 1em;
					line-height: 1.5em;
					white-space: normal;
					text-align: left;
				}
				.sales {
					position: absolute;
					left: -4rem;
					top: 4rem;
					width: 14rem;
					height: 4rem;
					background-color: #fff;
					h3 {
						margin-left: 4rem;
						font-size: 1rem;
						line-height: 1.5rem;
						padding: .5rem;
						height: 4rem;
						overflow: hidden;
						white-space: nowrap;
					}
				}
			}
		}
	}
	.property-spacer {
		position: sticky;
		top: 0;
		width: 100%;
		height: 15rem;
		background-color: transparent;
		&:last-child {
			display: none;
		}
	}
}