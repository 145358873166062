@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icomoon.eot?iti3kp');
	src:  url('../fonts/icomoon.eot?iti3kp#iefix') format('embedded-opentype'),
	url('../fonts/icomoon.ttf?iti3kp') format('truetype'),
	url('../fonts/icomoon.woff?iti3kp') format('woff'),
	url('../fonts/icomoon.svg?iti3kp#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-sum:before {
	content: "\e987";
}
.icon-lidohome .path1:before {
	content: "\e979";
	color: rgb(147, 34, 39);
}
.icon-lidohome .path2:before {
	content: "\e97a";
	margin-left: -3.515625em;
	color: rgb(29, 29, 27);
}
.icon-lidohome .path3:before {
	content: "\e97b";
	margin-left: -3.515625em;
	color: rgb(29, 29, 27);
}
.icon-lidohome .path4:before {
	content: "\e97c";
	margin-left: -3.515625em;
	color: rgb(255, 255, 255);
}
.icon-lidohome .path5:before {
	content: "\e97d";
	margin-left: -3.515625em;
	color: rgb(255, 255, 255);
}
.icon-lidohome .path6:before {
	content: "\e97e";
	margin-left: -3.515625em;
	color: rgb(255, 255, 255);
}
.icon-lidohome .path7:before {
	content: "\e97f";
	margin-left: -3.515625em;
	color: rgb(255, 255, 255);
}
.icon-casanetwork .path1:before {
	content: "\e974";
	color: rgb(61, 61, 60);
}
.icon-casanetwork .path2:before {
	content: "\e975";
	margin-left: -3.3251953125em;
	color: rgb(228, 0, 58);
}
.icon-casanetwork .path3:before {
	content: "\e976";
	margin-left: -3.3251953125em;
	color: rgb(255, 255, 255);
}
.icon-casanetwork .path4:before {
	content: "\e977";
	margin-left: -3.3251953125em;
	color: rgb(255, 255, 255);
}
.icon-casanetwork .path5:before {
	content: "\e978";
	margin-left: -3.3251953125em;
	color: rgb(255, 255, 255);
}
.icon-interaction:before {
	content: "\e973";
}
.icon-archive-marked:before {
	content: "\e970";
}
.icon-more-options:before {
	content: "\e96b";
}
.icon-setup:before {
	content: "\e96c";
}
.icon-more:before {
	content: "\e96a";
}
.icon-out-of-box:before {
	content: "\e967";
}
.icon-called-by-me:before {
	content: "\e95f";
}
.icon-called-by-other:before {
	content: "\e960";
}
.icon-uncalled:before {
	content: "\e961";
}
.icon-clipboards:before {
	content: "\e95c";
}
.icon-privatead:before {
	content: "\e95b";
}
.icon-graduation:before {
	content: "\e958";
}
.icon-into-box:before {
	content: "\e94c";
}
.icon-openbox:before {
	content: "\e94b";
}
.icon-dropbox:before {
	content: "\e94b";
}
.icon-agreement:before {
	content: "\e94a";
}
.icon-sales-2:before {
	content: "\e947";
}
.icon-sales-3:before {
	content: "\e948";
}
.icon-search-3:before {
	content: "\e949";
}
.icon-bank:before {
	content: "\e92b";
}
.icon-cheap:before {
	content: "\e938";
}
.icon-investments:before {
	content: "\e939";
}
.icon-rating:before {
	content: "\e943";
}
.icon-tree:before {
	content: "\e944";
}
.icon-urgent:before {
	content: "\e945";
}
.icon-add-home:before {
	content: "\e931";
}
.icon-drag:before {
	content: "\e92f";
}
.icon-list:before {
	content: "\e93e";
}
.icon-phone-search:before {
	content: "\e93c";
}
.icon-arrow-left:before {
	content: "\e93a";
}
.icon-arrow-right:before {
	content: "\e93b";
}
.icon-blueprint:before {
	content: "\e92c";
}
.icon-cash:before {
	content: "\e92e";
}
.icon-myhomes:before {
	content: "\e932";
}
.icon-presentation:before {
	content: "\e934";
}
.icon-statement:before {
	content: "\e937";
}
.icon-properties:before {
	content: "\e921";
}
.icon-add:before {
	content: "\e920";
}
.icon-add-web:before {
	content: "\e906";
}
.icon-role-add:before {
	content: "\e907";
}
.icon-add-envelope:before {
	content: "\e913";
}
.icon-add-phone:before {
	content: "\e914";
}
.icon-add-office:before {
	content: "\e91d";
}
.icon-permissions:before {
	content: "\e91b";
}
.icon-role:before {
	content: "\e91c";
}
.icon-label:before {
	content: "\e917";
}
.icon-office:before {
	content: "\e911";
}
.icon-network2:before {
	content: "\e912";
}
.icon-ip-logo-f:before {
	content: "\e90e";
	color: #fff;
}
.icon-upload-to-com:before {
	content: "\e980";
}
.icon-no-to-com:before {
	content: "\e981";
}
.icon-once-agreement:before {
	content: "\e982";
}
.icon-meeting:before {
	content: "\e983";
}
.icon-ok:before {
	content: "\e971";
}
.icon-target:before {
	content: "\e96d";
}
.icon-number-4:before {
	content: "\e962";
}
.icon-number-3:before {
	content: "\e963";
}
.icon-number-2:before {
	content: "\e964";
}
.icon-number-1:before {
	content: "\e965";
}
.icon-bigger:before {
	content: "\e959";
}
.icon-smaller:before {
	content: "\e95a";
}
.icon-archive:before {
	content: "\e957";
}
.icon-brightness-inc:before {
	content: "\e951";
}
.icon-brightness-dec:before {
	content: "\e952";
}
.icon-unlock:before {
	content: "\e93f";
}
.icon-lock:before {
	content: "\e940";
}
.icon-key:before {
	content: "\e941";
}
.icon-hourglass:before {
	content: "\e942";
}
.icon-board:before {
	content: "\e92d";
}
.icon-houses:before {
	content: "\e930";
}
.icon-on-beach:before {
	content: "\e933";
}
.icon-sales:before {
	content: "\e935";
}
.icon-search-2:before {
	content: "\e936";
}
.icon-traffic:before {
	content: "\e92a";
}
.icon-truck:before {
	content: "\e946";
}
.icon-transport:before {
	content: "\e946";
}
.icon-transaction:before {
	content: "\e946";
}
.icon-shipping:before {
	content: "\e946";
}
.icon-phone-single:before {
	content: "\e929";
}
.icon-blog-list:before {
	content: "\e926";
}
.icon-grid:before {
	content: "\e925";
}
.icon-world:before {
	content: "\e91e";
}
.icon-globe:before {
	content: "\e91e";
}
.icon-internet:before {
	content: "\e91e";
}
.icon-network:before {
	content: "\e91e";
}
.icon-label-add:before {
	content: "\e916";
}
.icon-web:before {
	content: "\e91f";
}
.icon-close:before {
	content: "\e918";
}
.icon-place:before {
	content: "\e900";
}
.icon-edit:before {
	content: "\f044";
}
.icon-house-contract:before {
	content: "\e901";
}
.icon-user-profile:before {
	content: "\e90d";
}
.icon-chevron-down:before {
	content: "\e985";
}
.icon-chevron-up:before {
	content: "\e986";
}
.icon-heart:before {
	content: "\e984";
}
.icon-clock-o:before {
	content: "\e96f";
}
.icon-mouse-pointer:before {
	content: "\e96e";
}
.icon-warning-filled:before {
	content: "\e969";
}
.icon-hand-pointer:before {
	content: "\e956";
}
.icon-chevron-left:before {
	content: "\e955";
}
.icon-chevron-right:before {
	content: "\e954";
}
.icon-external-link:before {
	content: "\e953";
}
.icon-crop:before {
	content: "\e94f";
}
.icon-ok-2:before {
	content: "\e94e";
}
.icon-refresh:before {
	content: "\e94d";
}
.icon-comments:before {
	content: "\e93d";
}
.icon-print:before {
	content: "\e928";
}
.icon-cart:before {
	content: "\e927";
}
.icon-at:before {
	content: "\e922";
}
.icon-search:before {
	content: "\f002";
}
.icon-settings:before {
	content: "\f013";
}
.icon-trash:before {
	content: "\f014";
}
.icon-home:before {
	content: "\f015";
}
.icon-file:before {
	content: "\f016";
}
.icon-download:before {
	content: "\f019";
}
.icon-question-circle:before {
	content: "\f059";
}
.icon-sign-out:before {
	content: "\f08b";
}
.icon-sign-in:before {
	content: "\f090";
}
.icon-upload:before {
	content: "\f093";
}
.icon-phone-square:before {
	content: "\f098";
}
.icon-copy:before {
	content: "\f0c5";
}
.icon-caret-down:before {
	content: "\f0d7";
}
.icon-caret-up:before {
	content: "\f0d8";
}
.icon-envelope:before {
	content: "\f0e0";
}
.icon-sitemap:before {
	content: "\f0e8";
}
.icon-double-arrow-left:before {
	content: "\f100";
}
.icon-double-arrow-right:before {
	content: "\f101";
}
.icon-folder:before {
	content: "\f114";
}
.icon-folder-open:before {
	content: "\f115";
}
.icon-sort-alpha-asc:before {
	content: "\f15d";
}
.icon-sort-alpha-desc:before {
	content: "\f15e";
}
.icon-sort-amount-asc:before {
	content: "\f160";
}
.icon-sort-amount-desc:before {
	content: "\f161";
}
.icon-sort-numeric-asc:before {
	content: "\f162";
}
.icon-sort-numeric-desc:before {
	content: "\f163";
}
.icon-file-pdf:before {
	content: "\f1c1";
}
.icon-file-word:before {
	content: "\f1c2";
}
.icon-file-excel:before {
	content: "\f1c3";
}
.icon-file-image:before {
	content: "\f1c5";
}
.icon-id-card:before {
	content: "\f2c3";
}
.icon-reset:before {
	content: "\e966";
}
.icon-filter:before {
	content: "\e972";
}
.icon-warning:before {
	content: "\e968";
}
.icon-stop:before {
	content: "\e95d";
}
.icon-play:before {
	content: "\e95e";
}
.icon-gallery:before {
	content: "\e950";
}
.icon-eye-slash:before {
	content: "\e919";
}
.icon-eye:before {
	content: "\e91a";
}
.icon-menu:before {
	content: "\e915";
}
.icon-arrow-down:before {
	content: "\e90f";
}
.icon-arrow-up:before {
	content: "\e910";
}
.icon-page-right:before {
	content: "\e902";
}
.icon-page-left:before {
	content: "\e903";
}
.icon-cancel:before {
	content: "\e904";
}
.icon-delete:before {
	content: "\e904";
}
.icon-save:before {
	content: "\e905";
}
.icon-calendar:before {
	content: "\e908";
}
.icon-users:before {
	content: "\e909";
}
.icon-user-remove:before {
	content: "\e90a";
}
.icon-user-add:before {
	content: "\e90b";
}
.icon-user:before {
	content: "\e90c";
}
.icon-exclude:before {
	content: "\e923";
}
.icon-include:before {
	content: "\e924";
}