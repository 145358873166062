@import "presentation";
.page-emailbemutato {
	article {
		.header {
			#logo:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: calc(100% - .5rem);
				width: 1rem;
				transform: skewX(26deg);
				background-color: #3f4444;
				z-index: 2;
				@include media-breakpoint-up(md) {
					left: calc(100% - 1rem);
					width: 2rem;
				}
			}
			.price {
				&:before {
					content: "";
					display: none;
					position: absolute;
					left: -1rem;
					width: 2rem;
					height: 4rem;
					background-color: theme-color('primary');
					transform: skewX(26deg);
				}
			}
			.remove-cart {
				transform: skewX(26deg);
				i {
					transform: skewX(-26deg) translateX(0.5rem);
				}
				&:before {
					display: block;
					content: "";
					background-color: theme-color('dark');
					width: 2rem;
					height: 4rem;
					position: absolute;
					top:0;
					right: -1rem;
					transform: skewX(-26deg);
				}
			}
		}
		.footer {
			background-color: lighten(theme-color('casa-light-blue'), 20%);
			.unsubscribe {
				button {
					border-color: theme-color('casa-blue');
					&:hover {
						background-color: #fff;
					}
				}
			}
		}
	}
}